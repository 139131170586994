import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ArrangementValuesComponent } from "../arrangement-values/arrangement-values.component";
import { Establishment } from 'src/app/shared/objects/establishment';
import { Tender } from 'src/app/shared/objects/tender';
import { FormsModule } from '@angular/forms';
import { TenderService } from 'src/app/services/Tender/tender.service';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-invoice-arrangements',
  standalone: true,
  imports: [FormsModule, CommonModule, ArrangementValuesComponent],
  templateUrl: './invoice-arrangements.component.html',
  styleUrl: './invoice-arrangements.component.scss'
})
export class InvoiceArrangementsComponent implements OnInit, OnDestroy {

  @Input() establishment?: Establishment;
  @Input() tender?: Tender;
  @Output() createInvoiceEvent = new EventEmitter<string>();
  activeTeamId?: string;
  errorSaving: boolean = false;
  isProcessingSavingTender: boolean = false;
  isViewer: boolean = true;
  subscribtions: (Subscription | undefined)[] = []; 

  constructor(private tenderService: TenderService, private communicationService: InternalCommunicationService){}
  
  ngOnInit(): void {
    this.subscribtions.push(this.communicationService.userIsViewer?.subscribe(isViewer => {
      this.isViewer = isViewer;
    }));
  }
  
  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  /**
   * Close window and return to PDF-Tender
   */
  close(){
    this.createInvoiceEvent?.emit('close-preparation');
  }

  /**
   * Trigger the event for Invoice creation
   */
  createInvoice(){
    if(!this.tender){
      return;
    }

    this.isProcessingSavingTender = true;

    // Saving is only available when the user is not a viewer
    if(this.isViewer){
        this.createInvoiceEvent?.emit('invoice');
        this.isProcessingSavingTender = false;
    } else {
      // Update tender and when successful create Invoice
      this.tenderService.updateTender(this.tender, this.communicationService.activeTeamId?.getValue()!).then(() => {
        this.createInvoiceEvent?.emit('invoice');
      }).catch(() => {
        this.errorSaving = true;
      }).finally(() =>{
        this.isProcessingSavingTender = false;
      });
    }
  }
}
