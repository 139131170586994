import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Api } from 'src/app/helpers/api';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { TenderService } from 'src/app/services/Tender/tender.service';
import { GuestValues } from 'src/app/shared/objects/components/guest-values';
import { Establishment } from 'src/app/shared/objects/establishment';
import { Tender } from 'src/app/shared/objects/tender';

@Component({
  standalone: true,
  imports: [
    FormsModule
  ],
  selector: 'app-guest-values',
  templateUrl: './guest-values.component.html',
  styleUrls: ['./guest-values.component.scss']
})
export class GuestValuesComponent implements OnInit {

  @Input() tender?: Tender
  @Input() isReadOnly?: boolean;
  @Input() isSheet?: boolean;
  @Input() establishment?: Establishment

  showAdressValuesSection: boolean;
  showContactValues: boolean = true;
  searchForGuest?: string;
  attachedGuest?: string;
  matchingGuestValues?: GuestValues[];
  activeTeamId?: string;
  validationResult = {
    phonenumberValid: true,
    emailAdressValid: true,
  }

  constructor(private communicationService: InternalCommunicationService, private tenderService: TenderService) {
    this.showAdressValuesSection = false;
   }

  ngOnInit(): void {
     // Check if prices needs to be shown
     if(this.isSheet && this.establishment){
      this.showContactValues = this.establishment.displayGuestContactValuesOnSheet;
    }

    // Set active teamId
    Api.teams.list().then(teamsList => {
      var team = teamsList.teams.filter(t => t.name == this.establishment?.establishmentName);
      if (team?.length > 0) {
        this.activeTeamId = team[0].$id;
      }
    });
  }

  /**
   * This method will toggle the adress values section
   */
   toggleAdressValuesSection(){
    this.showAdressValuesSection = !this.showAdressValuesSection;
  }

  /**
   * This method will search for existing guests in the GuestValues database
   */
  searchForGuests(){
    if(!this.searchForGuest){
      this.matchingGuestValues = [];
      return;
    }

    this.tenderService.searchExistingGuest(this.searchForGuest).then(matchingGuestValues =>{
      if(matchingGuestValues.total == 0){
        this.matchingGuestValues = [];
        return;
      }
      
      if(matchingGuestValues?.total > 0 && this.activeTeamId){
        var matchingResults: GuestValues[] = [];
        matchingGuestValues.documents.forEach(m => {
          m.$permissions.forEach(permission => {
            if(permission.includes(this.activeTeamId!) && !matchingResults.includes(m)){
              matchingResults.push(m);
            }
          })
        })

        this.matchingGuestValues = matchingResults;
      }
    })
  }

  /**
   * This method will set the given set of GuestValues as current GuestValues for this Tender
   * @param guestValues 
   */
  useGuestValues(guestValues: GuestValues){
    if(this.tender){
      this.tender.guestValues = guestValues;
      this.attachedGuest = `${guestValues.firstname} ${guestValues.insertion ?? ""} ${guestValues.lastname}`
      this.matchingGuestValues = [];
      this.searchForGuest = undefined;
      this.guestValuesChanged();
    }
  }

  /**
   * This method will detach the current guest
   */
  dettachGuest(){
    this.attachedGuest = undefined;
    
    if(this.tender){
      this.tender.guestValues = {
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        adressedToMultiplePeople: false
      }

      this.communicationService.proposedTenderName?.next("");
    }
  }

  /**
   * This method will toggle the AdressedTo State
   */
   toggleAdressedToFields(){
    // Safety Checks
    if(!this.tender){
      return;
    }

    if(!this.tender.guestValues){
      return;
    }

    // Toggle
    this.tender.guestValues.adressedToMultiplePeople = !this.tender.guestValues.adressedToMultiplePeople;
  }

  /**
   * This method will check if a phonenumber is valid
   */
   phonenumberValidation(){
    this.guestValuesChanged();
    // Safety Checks
    if(!this.tender){
      return;
    }

    if(!this.tender.guestValues){
      return;
    }

    if(!this.tender.guestValues.phonenumber){
      // If not present than remove message
      this.validationResult.phonenumberValid = true;
      return;
    }

    // Validate with Regex
    if(this.tender.guestValues.phonenumber.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)){
      this.validationResult.phonenumberValid = true;
      return;
    }

    this.validationResult.phonenumberValid = false;

  }

    /**
   * This method will check if a email adress is valid
   */
     emailAdressValidation(){
      this.guestValuesChanged();
      // Safety Checks
      if(!this.tender){
        return;
      }
  
      if(!this.tender.guestValues){
        return;
      }
  
      if(!this.tender.guestValues.emailAdress){
        // If not present than remove message
        this.validationResult.emailAdressValid = true;
        return;
      }
  
      // Validate with Regex
      if(this.tender.guestValues.emailAdress.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
        this.validationResult.emailAdressValid = true;
        return;
      }
  
      this.validationResult.emailAdressValid = false;  
    }

    /**
     * This method will be triggerd when the input has changed
     */
    guestValuesChanged(){
      if(this.communicationService.shallowTender && this.tender){
        var changes = this.tenderService.compareForChanges(this.communicationService.shallowTender, this.tender);
        if(changes && !this.communicationService.unsavedChanges){
          this.communicationService.unsavedChanges = true;
        }
      }

      if(this.tender?.guestValues?.firstname && this.tender?.guestValues?.lastname){
        this.communicationService.proposedTenderName.next("Update GuestValues");
      }
    }
}
