@if(tender && tender.invoiceValues && !isReadOnly){
    <div class="row">
        <div class="form-group col-4">
            <label for="ascription">Tenaamstelling 
                @if(tender.invoiceValues.ascriptionFromTender){
                    <span class="attached">(Gekoppeld)</span>
                }
            </label>
            <div class="button-input-group">
                <input [disabled]="tender.invoiceValues.ascriptionFromTender" maxlength="255" type="input" class="form-control" id="ascription" placeholder="Tenaamstelling" [(ngModel)]="tender.invoiceValues.ascription">
                <button class="btn btn-secondary" (click)="copyAscription()">
                    @if(tender.invoiceValues.ascriptionFromTender){
                        <i class="bi bi-pencil-square"></i>
                    } @else {
                        <i class="bi bi-hand-index-thumb"></i>
                    }
                </button>
            </div>
        </div>
        <div class="form-group col-8">
            <label for="invoiceSummery">Omschrijving</label>
            <input maxlength="255" type="input" class="form-control" id="invoiceSummery" placeholder="Omschrijving" [(ngModel)]="tender.invoiceValues.invoiceSummary">
        </div>
        <div class="form-group col-4">
            <label for="invoiceEmailAdress">Factuur e-mailadres 
                @if(tender.invoiceValues.emailAdressFromTender){
                    <span class="attached">(Gekoppeld)</span>
                }
            </label>
            <div class="button-input-group">
                <input [disabled]="tender.invoiceValues.emailAdressFromTender" maxlength="255" type="email" class="form-control" id="invoiceEmailAdress" placeholder="Emailadres" [(ngModel)]="tender.invoiceValues.emailAdress">
                <button class="btn btn-secondary" (click)="copyEmailAdress()">
                    @if(tender.invoiceValues.emailAdressFromTender) {
                        <i class="bi bi-pencil-square"></i>
                    } @else {
                        <i class="bi bi-hand-index-thumb"></i>
                    }
                </button>
            </div>
        </div>
        <div class="form-group col-2">
            <label for="invoiceNumber">Factuurnummer</label>
            <input maxlength="60" type="input" class="form-control" id="invoiceNumber" placeholder="Factuurnummer" [(ngModel)]="tender.invoiceValues.invoiceNumber">
        </div>
        <div class="form-group col-2">
            <label for="allocation">Kostenplaats</label>
            <input maxlength="255" type="input" class="form-control" id="allocation" placeholder="Kostenplaats" [(ngModel)]="tender.invoiceValues.allocation">
        </div>
        <div class="form-group col-2">
            <label for="vatId">BTW-nummer</label>
            <input maxlength="255" type="input" class="form-control" id="vatId" placeholder="BTW-nummer" [(ngModel)]="tender.invoiceValues.vatId">
        </div>
        <div class="form-group col-2">
            <label for="kvkNumber">KvK-nummer</label>
            <input maxlength="255" type="input" class="form-control" id="kvkNumber" placeholder="KvK-nummer" [(ngModel)]="tender.invoiceValues.KvkNumber">
        </div>
    </div>
}
@if(isReadOnly){
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col d-flex flex-column">
                    <div class="mb-2 d-flex flex-column">
                        <span><strong>Ter attentie van</strong>:</span>
                        <span>{{tender?.invoiceValues?.ascription ?? getAdressedTo()}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex flex-column">
                    <div class="mt-2 d-flex flex-column">
                        <span><strong>Referentieadres:</strong></span>
                        <span>{{getAdress()}}</span>
                    </div>
                    @if(tender?.invoiceValues?.invoiceSummary){
                        <div class="mt-2 d-flex flex-column">
                            <span><strong>Omschrijving:</strong></span>
                            <span>{{tender?.invoiceValues?.invoiceSummary}}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div class="col row">
            <div class="col d-flex flex-column">
                <span><strong>Factuurnummer</strong>:</span>
                <span>Factuurdatum:</span>
                <span>Vervaldatum:</span>
                <div class="mt-2 d-flex flex-column">
                    @if(tender?.invoiceValues?.allocation){
                        <span>Kostenplaats:</span>
                    }
                    @if(tender?.invoiceValues?.KvkNumber){
                        <span>KvK-nummer:</span>
                    }
                    @if(tender?.invoiceValues?.vatId){
                        <span>BTW-nummer:</span>
                    }
                </div>
            </div>
            <div class="col d-flex flex-column">
                <span>{{this.tender?.invoiceValues?.invoiceNumber ?? 'Onbekend'}}</span>
                <span>{{today | date: 'dd-MM-yyyy'}}</span>
                <span>{{expirationDate | date: 'dd-MM-yyyy'}}</span>
                <div class="mt-2 d-flex flex-column">
                    @if(tender?.invoiceValues?.allocation){
                        <span>{{tender?.invoiceValues?.allocation}}</span>
                    }
                    @if(tender?.invoiceValues?.KvkNumber){
                        <span>{{tender?.invoiceValues?.KvkNumber}}</span>
                    }
                    @if(tender?.invoiceValues?.vatId){
                        <span>{{tender?.invoiceValues?.vatId}}</span>
                    }
                </div>
            </div>
        </div>
    </div>
}
