@if(tender && tender.guestValues){
    @if(!isSheet && !isReadOnly){
        <div class="row">
            @if(!attachedGuest){
                <div class="search-for-existing">
                    <div class="form-group col">
                        <label for="multipleNames" id="firstname-label">Bestaande gast zoeken</label>
                        <input type="input" maxlength="255" class="form-control" id="existingGuests" placeholder="E-mailadres..." [(ngModel)]="searchForGuest" (ngModelChange)="searchForGuests()">
                    </div>
                </div>
            }
            @if((searchForGuest?.length ?? 0) > 0){
                <div class="matching-guest-values">
                    <span class="label">Overeenkomsten:</span>
                    <div class="results">
                        @for(matchingGuestValue of matchingGuestValues; track matchingGuestValue.$id){
                            <div class="matching-result" (click)="useGuestValues(matchingGuestValue)">
                                <span><i class="bi bi-person"></i><span class="adres-wrapper">{{matchingGuestValue.emailAdress}}</span></span>
                            </div>
                        } @empty {
                            <div class="matching-guest-values">
                                <span class="no-matches">Er zijn geen overeenkomstige gasten gevonden</span>
                            </div>
                        }
                    </div>
                </div>
            }    
            @if(attachedGuest){
                <div class="attached-guest" (click)="dettachGuest()">
                    <span>Gekoppelde gast: <strong>{{attachedGuest}}</strong></span><div class="dettach">Loskoppelen<i class="bi bi-x-circle"></i></div>
                </div>
            } @else {
                <hr>
            }
        </div>
    }
    <div class="row">
        @if (tender.guestValues.adressedToMultiplePeople){
            <div class="form-group col">
                <label for="multipleNames" id="firstname-label">Namen</label>
                @if(isReadOnly){
                    <input type="input" readonly class="form-control-plaintext" id="multipleNames" [(ngModel)]="tender.guestValues.firstname">
                } @else {
                    <input maxlength="255" type="input" class="form-control" id="multipleNames" placeholder="Namen" [(ngModel)]="tender.guestValues.firstname" (ngModelChange)="guestValuesChanged()">
                }
            </div>
        } @else {
            <div class="form-group col-4">
                <label for="firstname" id="firstname-label">Voornaam</label>
                @if(isReadOnly){
                    <input type="input" readonly class="form-control-plaintext" id="firstname" [(ngModel)]="tender.guestValues.firstname">
                } @else {
                    <input maxlength="255" type="input" class="form-control" id="firstname" placeholder="Voornaam" [(ngModel)]="tender.guestValues.firstname" (ngModelChange)="guestValuesChanged()">
                }
            </div>
            @if((!isReadOnly || (isReadOnly && tender.guestValues.insertion))){
                <div class="form-group col-2">
                    <label for="insertion">Tussenvoegsel</label>
                    @if(isReadOnly){
                        <input type="input" readonly class="form-control-plaintext" id="insertion" [(ngModel)]="tender.guestValues.insertion">
                    } @else {
                        <input maxlength="255" type="input" class="form-control" id="insertion" placeholder="Tussenvoegsel" [(ngModel)]="tender.guestValues.insertion" (ngModelChange)="guestValuesChanged()">
                    }
                </div>
            }
            <div class="form-group col">
                <label for="lastname">Achternaam</label>
                @if(isReadOnly){
                    <input type="input" readonly class="form-control-plaintext" id="lastname" [(ngModel)]="tender.guestValues.lastname">
                } @else {
                    <input maxlength="255" type="input" class="form-control" id="lastname" placeholder="Achternaam" [(ngModel)]="tender.guestValues.lastname" (ngModelChange)="guestValuesChanged()">
                }
            </div>
        }
        @if(!isReadOnly){
            <div class="form-group col-auto multiple-names-section">
                <label class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Betreft het een enkel persoon of meerdere personen" (click)="toggleAdressedToFields()">
                    @if(tender.guestValues.adressedToMultiplePeople){
                        <i class="bi bi-people" id="multiple-person"></i>
                    } @else {
                        <i class="bi bi-person" id="single-person"></i>
                    }
                </label>
            </div>
        }
    </div>
    <div class="row">
        @if(!isSheet || showContactValues){
            <div class="form-group col-4">
                <label for="phonenumber">Telefoonnummer</label>
                @if(isReadOnly){
                    <input type="input" class="form-control-plaintext" readonly id="phonenumber" [(ngModel)]="tender.guestValues.phonenumber" (ngModelChange)="phonenumberValidation()">
                } @else {
                    <input maxlength="255" type="input" class="form-control" id="phonenumber" placeholder="Telefoonnummer" [(ngModel)]="tender.guestValues.phonenumber" (ngModelChange)="phonenumberValidation()">
                }
                @if(!validationResult.phonenumberValid){
                    <small class="text-muted invalid">
                        Het ingevoerde telefoonnummer is ongeldig
                    </small>
                }
            </div>
            <div class="form-group col-5">
                <label for="emailadress">E-mailadres</label>
                @if(isReadOnly){
                    <input type="email" class="form-control-plaintext" id="emailadress" [(ngModel)]="tender.guestValues.emailAdress" (ngModelChange)="emailAdressValidation()">
                } @else {
                    <input maxlength="255" type="email" class="form-control" id="emailadress" placeholder="Email Adres" [(ngModel)]="tender.guestValues.emailAdress" (ngModelChange)="emailAdressValidation()">
                }
                @if(!validationResult.emailAdressValid){
                    <small class="text-muted invalid">
                        Het ingevoerde email-adres is ongeldig
                    </small>
                }
            </div>
        }
        @if(!isReadOnly || (isReadOnly && tender.guestValues.companyName)){
            <div class="form-group col">
                <label for="companyName">Bedrijfsnaam</label>
                @if(isReadOnly){
                    <input readonly type="companyName" class="form-control-plaintext" id="companyName" [(ngModel)]="tender.guestValues.companyName">
                } @else {
                    <input maxlength="255" type="companyName" class="form-control" id="companyName" placeholder="Bedrijfsnaam" [(ngModel)]="tender.guestValues.companyName" (ngModelChange)="guestValuesChanged()">
                }
            </div>
        }
    </div>
    @if((showAdressValuesSection) || (isReadOnly && tender && tender.guestValues && tender.guestValues.zipcode)){
        <div class="adress-values-section">
            @if(!isSheet || showContactValues){
                <div class="adress-values hide-NAW" id="adressValues">
                    <div class="row">
                        <div class="form-group col-4">
                            <label for="housenumber">Straatnaam</label>
                            @if(isReadOnly){
                                <input readonly type="input" class="form-control-plaintext" id="streetname" [(ngModel)]="tender.guestValues.street">
                            } @else {
                                <input maxlength="255" type="input" class="form-control" id="streetname" placeholder="Straatnaam" [(ngModel)]="tender.guestValues.street" (ngModelChange)="guestValuesChanged()">
                            }
                        </div>
                        <div class="form-group col-2">
                            <label for="housenumber">Huisnummer</label>
                            @if(isReadOnly){
                                <input readonly type="input" class="form-control-plaintext" id="housenumber" [(ngModel)]="tender.guestValues.number">
                            } @else {
                                <input maxlength="255" type="input" class="form-control" id="housenumber" placeholder="Huisnummer" [(ngModel)]="tender.guestValues.number" (ngModelChange)="guestValuesChanged()">
                            }
                        </div>
                        <div class="form-group col-3">
                            <label for="zipcode">Postcode</label>
                            @if(isReadOnly){
                                <input readonly type="input" class="form-control-plaintext" id="zipcode" [(ngModel)]="tender.guestValues.zipcode">
                            } @else {
                                <input maxlength="255" type="input" class="form-control" id="zipcode" placeholder="Postcode" [(ngModel)]="tender.guestValues.zipcode" (ngModelChange)="guestValuesChanged()">
                            }
                        </div>
                        <div class="form-group col">
                            <label for="city">Woonplaats</label>
                            @if(isReadOnly){
                                <input readonly type="input" class="form-control-plaintext" id="city" [(ngModel)]="tender.guestValues.city">
                            } @else {
                                <input maxlength="255" type="input" class="form-control" id="city" placeholder="Woonplaats" [(ngModel)]="tender.guestValues.city" (ngModelChange)="guestValuesChanged()">
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    }
    @if(!isReadOnly){
        <div class="adres-section-actions">
            @if(showAdressValuesSection){
                <button (click)="toggleAdressValuesSection()" type="button" class="btn btn-danger" id="add-adress-values" data-bs-toggle="tooltip" data-bs-placement="top" title="Adresgegevens zullen alleen worden getoond indien uitgevouwen"> - Adresgegevens verbergen </button>
            } @else {
                <button (click)="toggleAdressValuesSection()" type="button" class="btn btn-secondary" id="add-adress-values" data-bs-toggle="tooltip" data-bs-placement="top" title="Adresgegevens zullen alleen worden getoond indien uitgevouwen"> + Adresgegevens toevoegen </button>
            }
        </div>
    }
}