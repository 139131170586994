import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Models } from 'appwrite';
import { Subject, Subscription } from 'rxjs';
import { Api } from 'src/app/helpers/api';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { TaskService } from 'src/app/services/Task/task.service';
import { TeamsService } from 'src/app/services/Teams/teams.service';
import { TaskInformation } from 'src/app/shared/objects/front-end/task-information';
import { Task } from 'src/app/shared/objects/task';
import { Tender } from 'src/app/shared/objects/tender';
import { UserPreferences } from 'src/app/shared/objects/userPreferences';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  selector: 'app-active-tasks',
  templateUrl: './active-tasks.component.html',
  styleUrls: ['./active-tasks.component.scss']
})
export class ActiveTasksComponent implements OnInit, OnDestroy {

  @Input() user?: Partial<Models.User<UserPreferences>>;
  @Input() tender?: Tender;
  @Input() tasks?: Subject<Task[]>;
  @Input() hideTenderName?: boolean;
  @Input() showIntroduction?: boolean;
  @Input() hideActions?: boolean;
  @Output() openTasks = new EventEmitter<number>();
  subscribtions: (Subscription | undefined)[] = []; 

  teamMembers?: Models.MembershipList;
  searchInput: string = "";
  deadlineWarning: number = 7;
  sortedBy?: string;
  sortedAscending?: boolean;
  successFeedback?: string;
  warningFeedback?: string;
  perPage: number;
  activePage: number;
  showCompleted?: boolean;
  showUserTasks?: boolean;
  isViewer: boolean = true;
  paginatedActiveTasks?: [Task[]];
  taskInformation?: TaskInformation[];
  activeTasks: Task[] = [];
  allTasks: Task[] = [];

  constructor(public taskService: TaskService, private teamService: TeamsService, private internalCommunication: InternalCommunicationService, public internalCommunicationSevice: InternalCommunicationService) {
    this.perPage = 5;
    this.activePage = 0;
  }

  async ngOnInit() {
    await this.getActiveTasks();

    // Subscribe on task input if present
    if (this.tasks) {
      this.tasks.subscribe(taskInput => {
        this.allTasks = taskInput;
        this.activeTasks = this.allTasks;
        this.paginateActiveTasks();
      })
    }

    this.subscribtions.push(this.internalCommunicationSevice.userIsViewer?.subscribe(isViewer => {
      this.isViewer = isViewer;
    }));

    this.subscribtions.push(this.internalCommunication.activeEstablishment.subscribe(activeEstablishment => {
      Api.teams.list().then(teamsList => {
        var team = teamsList.teams.filter(t => t.name == activeEstablishment?.establishmentName);
        if (team?.length > 0) {
          this.teamService.getTeamMembers(team[0].$id).then(members => {
            this.teamMembers = members;
          });
        }
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  /**
   * Toggle list for showing completed or open tasks
   */
  toggleShowCompleted(){
    this.showCompleted = !this.showCompleted;
    this.onCompletedChanged();
  }

  /**
   * Toggle show only users tasks or all tasks
   */
  toggleShowUserTasks(){
    this.showUserTasks = !this.showUserTasks;
    this.paginateActiveTasks();
  }

  /**
   * This method will return the name of the tender which belongs to the task
   * @param taskId 
   */
  getTenderName(taskId: string) {
    if (!this.taskInformation) {
      this.taskInformation = [];
    }

    var tasksInformation = this.taskInformation.filter(t => t.taskId == taskId);

    if (tasksInformation?.length == 0) {
      return "Onbekend";
    }
    return tasksInformation[0].tenderName;
  }

  /**
   * Find the attached users of the task
   * @param task 
   */
  getAttachedUsers(task: Task){
    if(!task || !task?.joinedAssignedUserIds){
      return [];
    }

    var assignedUserIds = task?.joinedAssignedUserIds.split(',') ?? [];
    return this.teamMembers?.memberships?.filter(ms => assignedUserIds?.includes(ms.userId));
  }

  searchTasks() {
    if (!this.searchInput || this.searchInput == "") {
      this.activeTasks = this.allTasks;
      this.paginateActiveTasks();
      return;
    }


    const datepipe: DatePipe = new DatePipe('en-US')
    this.activeTasks = this.allTasks.filter(
      task =>
        task.taskName?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        task.status.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        datepipe.transform(task.deadlineDate, 'dd-MM-yyyy')?.toString().includes(this.searchInput.toLowerCase())
    );

    this.paginateActiveTasks();
  }

  /**
   * Send request to open task editor
   * @param taskId 
   */
  public editTask(taskId: string) {
    if (!taskId) {
      return;
    }

    this.internalCommunicationSevice.editTaskId.next(taskId);
  }

  /**
   * This method will filter the list for completed tasks
   */
  onCompletedChanged() {
    this.paginateActiveTasks();
  }

  /**
   * This method will make the user able to remove a tender
   * @param tender 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async removeTask(taskId: string, index: number, confirmationNeeded: boolean) {
    if (confirmationNeeded) {
      var confirmButton = document.getElementById("confirm-remove-task" + index);
      var cancelButton = document.getElementById("cancel-remove-task" + index);
      var removeButton = document.getElementById("remove-task" + index);
      var editButton = document.getElementById("edit-task" + index);

      if (confirmButton && removeButton && cancelButton && editButton) {
        confirmButton.style.display = "flex";
        cancelButton.style.display = "flex";
        removeButton.style.display = "none";
        editButton.style.display = "none";
      }
      return;
    }

    if (!taskId) {
      return;
    }

    this.taskService.deleteTask(taskId).then(() => {
      this.allTasks = this.allTasks.filter(t => t.$id != taskId);
      this.tasks?.next(this.allTasks);
      this.activeTasks = this.allTasks;
      this.paginateActiveTasks();
    })
  }

  /**
   * This methow will cancel a remove action
   * @param index 
   */
  cancelTaskRemoval(index: number) {
    var confirmButton = document.getElementById("confirm-remove-task" + index);
    var cancelButton = document.getElementById("cancel-remove-task" + index);
    var removeButton = document.getElementById("remove-task" + index);
    var editButton = document.getElementById("edit-task" + index);

    if (confirmButton && removeButton && cancelButton && editButton) {
      confirmButton.style.display = "none";
      cancelButton.style.display = "none";
      removeButton.style.display = "block";
      editButton.style.display = "block";
    }
  }

  /**
   * This method will check if the deadline is within the next X days
   * @param deadline 
   * @returns 
   */
  deadLineInRange(deadline: Date | undefined) {
    if (!deadline) {
      return false;
    }

    var today = new Date();
    var nextWeek = Date.parse(new Date(today.getFullYear(), today.getMonth(), today.getDate() + this.deadlineWarning).toString());
    var nextWeekDate = new Date(nextWeek);
    var mappedDeadlineDate = Date.parse(deadline.toString());
    var deadlineDate = new Date(mappedDeadlineDate);

    var diff = Math.abs(deadlineDate.getTime() - nextWeekDate.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    if (diffDays <= this.deadlineWarning) {
      return true;
    }

    return false;
  }

  /**
   * Sort by the given column
   * @param sortBy 
   * @returns 
   */
  sortTasksBy(sortBy: string) {
    this.sortedBy = sortBy;

    if (sortBy == "expiration date") {
      if (this.sortedBy == sortBy && this.sortedAscending) {
        this.sortedAscending = false;
        this.activeTasks.sort((a: Task, b: Task) => {
          return this.getDateOfEventTime(b) - this.getDateOfEventTime(a);
        });
        this.paginateActiveTasks();
        return;
      }

      // Sort Ascending
      this.activeTasks.sort((a: Task, b: Task) => {
        return this.getDateOfEventTime(a) - this.getDateOfEventTime(b)
      });

      this.sortedAscending = true;
      this.sortedBy = sortBy;
    } else if (sortBy == "attachedUsers") {
      if (this.sortedBy == sortBy && this.sortedAscending) {
        this.sortedAscending = false;
        this.activeTasks.sort(function (a: Task, b: Task) {
          var textA = b.joinedAssignedUserIds?.toUpperCase();
          var textB = a.joinedAssignedUserIds?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveTasks();
        return;
      }

      this.activeTasks.sort(function (a: Task, b: Task) {
        var textA = a.joinedAssignedUserIds?.toUpperCase();
        var textB = b.joinedAssignedUserIds?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this.sortedAscending = true;
      this.sortedBy = sortBy;
    } else if (sortBy == "status") {
      if (this.sortedBy == sortBy && this.sortedAscending) {
        this.sortedAscending = false;
        this.activeTasks.sort(function (a: Task, b: Task) {
          var textA = b.status?.toUpperCase();
          var textB = a.status?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveTasks();
        return;
      }

      this.activeTasks.sort(function (a: Task, b: Task) {
        var textA = a.status?.toUpperCase();
        var textB = b.status?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this.sortedAscending = true;
      this.sortedBy = sortBy;
    } else if (sortBy == "tenderName") {
      if (this.sortedBy == sortBy && this.sortedAscending) {
        this.sortedAscending = false;
        this.activeTasks.sort(function (a: Task, b: Task) {
          var textA = b.tenderName?.toUpperCase();
          var textB = a.tenderName?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveTasks();
        return;
      }

      this.activeTasks.sort(function (a: Task, b: Task) {
        var textA = a.status?.toUpperCase();
        var textB = b.status?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this.sortedAscending = true;
      this.sortedBy = sortBy;
    }

    this.paginateActiveTasks();
  }


  /**
   * This method will pagina the active tasks
   * @returns 
   */
  paginateActiveTasks() {
    if (!this.activeTasks) {
      return;
    }

    var nonArchivedTasks = this.activeTasks.filter(t => !t.archived);

    // Filter out completed tasks if needed
    if (!this.showCompleted) {
      nonArchivedTasks = nonArchivedTasks.filter(t => t.status != "Afgerond");
    }

    // Filter tasks which are not relevant for the current user
    if (this.showUserTasks && this.user?.$id){
      nonArchivedTasks = nonArchivedTasks.filter(t => t.joinedAssignedUserIds?.includes(this.user!.$id ?? ""));
    }

    for (var i = 0; i < nonArchivedTasks.length / this.perPage; i++) {
      if (i == 0) {
        this.paginatedActiveTasks = [nonArchivedTasks.slice(i * this.perPage, i * this.perPage + this.perPage)];
        
        continue;
      }

      if (!this.paginatedActiveTasks) {
        return;
      }

      this.paginatedActiveTasks.push(nonArchivedTasks.slice(i * this.perPage, i * this.perPage + this.perPage));
    }

    if(nonArchivedTasks.length == 0){
      this.paginatedActiveTasks = [[]];
    } 

    if (!this.paginatedActiveTasks) {
      return;
    }

    if (this.activePage >= this.paginatedActiveTasks?.length) {
      this.activePage = 0;
    }
  }

  async getActiveTasks() {
    if (this.tender) {
      this.taskService.getTasksOfTender(this.tender.$id!).then(tasksOfTender => {
        if (!tasksOfTender) {
          return;
        }

        this.allTasks = tasksOfTender;
        this.activeTasks = this.allTasks;
        this.paginateActiveTasks();
      });
    } else if (this.user) {
      if (!this.user.prefs?.activeEstablishmentId) {
        return;
      }

      // Retrieve from communicationService
      var tasks = this.internalCommunicationSevice.activeTasks?.getValue();
      var tenders = this.internalCommunicationSevice.activeTenders.getValue();

      this.activeTasks = tasks;
      this.allTasks = this.activeTasks;
      this.taskInformation = [];
      this.paginateActiveTasks();

      var searchedTenders: Tender[] = [];
      tasks.forEach(task => {

        var matchingTenders = searchedTenders.filter(t => t.$id == task.tenderId);
        if (matchingTenders.length > 0) {
          this.taskInformation?.push({
            taskId: task.$id,
            tenderName: matchingTenders[0].tenderName
          });

          task.tenderName = matchingTenders[0].tenderName;
          return;
        }

        if (task.tenderId) {
          var tender = tenders?.filter(t => t.$id == task.tenderId);

          if(tender?.length > 0){
              // Store for efficiency purpose
              searchedTenders.push(tender[0]);

              this.taskInformation?.push({
                taskId: task.$id,
                tenderName: tender[0]?.tenderName
              });
  
              task.tenderName = tender[0]?.tenderName;
          }
        }
      })
    }
  }

  statusSelectionChanged(task: Task, status: string) {
    if (!task || !status) {
      return;
    }

    task.status = status;
    this.openTasks.emit(this.allTasks.filter(t => t.status != "Afgerond" && !t.archived && (t.joinedAssignedUserIds?.includes(this.user?.$id ?? "") || !t.joinedAssignedUserIds || t.joinedAssignedUserIds == "")).length);

    this.taskService.updateTask(task).then(result => {
      if (result) {
        this.successFeedback = "De status van taak: " + task.taskName + " is succesvol aangepast.";
        setTimeout(() => { this.successFeedback = undefined }, 3000);
      } else {
        this.warningFeedback = "De status van taak: " + task.taskName + " is niet aangepast.";
        setTimeout(() => { this.warningFeedback = undefined }, 3000);
      }
    })
  }

  /**
   * This method will toggle the visibility of a task description
   * @param index 
   * @returns 
   */
  toggleTaskDescription(index: number) {
    var card = document.querySelector("#collapseTask" + index);
    var toggleButton = document.querySelector("#toggleTask" + index);

    if (!card || !toggleButton) {
      return;
    }

    if (card.classList.contains("show")) {
      card.classList.remove("show");
      card.classList.add("collapse");
      toggleButton.classList.remove("bi-caret-up-square");
      toggleButton.classList.add("bi-caret-down-square");
      return;
    }

    card.classList.remove("collapse");
    card.classList.add("show");
    toggleButton.classList.remove("bi-caret-down-square");
    toggleButton.classList.add("bi-caret-up-square");
  }

  /**
 * This method will set the tender id to the given id so that the other status line could be accessed
 * @param tenderId 
 */
  onStatusExpanderClick(taskId: string | undefined) {
    this.internalCommunicationSevice.openStatusTaskIdChanged = true;
    if (this.internalCommunicationSevice.openStatusTaskId == taskId) {
      this.internalCommunicationSevice.openStatusTaskId = undefined;
    }
    this.internalCommunicationSevice.openStatusTaskId = taskId;
  }

  private getDateOfEventTime(task?: Task | undefined) {
    if (!task?.deadlineDate) {
      return 0;
    }

    return task.deadlineDate != null ? new Date(task.deadlineDate).getTime() : 0;
  }
}
