<div class="pdf-tender-wrapper">
    @if(prepareInvoice){
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card mt-3 mb-5">
                        <div class="card-body">
                            <app-invoice-arrangements [tender]="tender" [establishment]="establishment" (createInvoiceEvent)="printTemplate($event)"></app-invoice-arrangements>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    } @else {
        @if(showPopUp){
            <div class="pop-over-wrapper">
                <div class="pop-over">
                    <h4><i class="bi bi-check-circle"></i> Export voorbereid</h4>
                    <p>Kies een van de onderstaande opties om te exporteren naar PDF of om te printen.</p>
                    <div class="pop-up-actions row">
                        <button class="btn btn-secondary col" (click)="printTemplate('kitchen-sheet')" [disabled]="!activePackage?.kitchenSheet"><i class="bi bi-filetype-pdf"></i>Keuken-Sheet</button>
                        <button class="btn btn-secondary col" (click)="printTemplate('sheet')"><i class="bi bi-filetype-pdf"></i>Sheet</button>
                        <button class="btn btn-secondary col" (click)="printTemplate('tender')"><i class="bi bi-filetype-pdf"></i>Offerte</button>
                        <button class="btn btn-secondary col" (click)="printTemplate('prepare-invoice')"><i class="bi bi-filetype-pdf"></i>Factuur aanmaken</button>
                        <button class="btn btn-danger col" (click)="onafterprint()"><i class="bi bi-x-lg"></i>Annuleren</button>
                    </div>
                </div>
            </div>
        }
        <div id="print-tender">
            @if(!isKitchenSheet){
                <div class="container-fluid" id="pdf-header" [style.background]="establishment?.establishmentBrandMainColor">
                    <div class="brand-icon">
                        <img src="{{logoReference}}" />
                    </div>
                    <div class="container">
                        <div class="row justify-content-center header-section" id="pdf-header-content" [ngStyle]="{ 'color': textColor }">
                            <div class="col header-title">
                                @if(isSheet){
                                    <h1>Sheet</h1>
                                } @else if(isInvoice){
                                    <h1>Factuur</h1>
                                }
                                @else{
                                    <h1>Offerte</h1>
                                }
                                <span>{{currentYear}} - Alle rechten voorbehouden</span>
                                @if(isSheet && establishment?.displayDayOfWeekOnSheet){
                                    <span class="dayOfEvent">{{this.dayOfWeek}}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            } @else {
                <div class="container-fluid" id="pdf-kitchen-sheet-header">
                    <div class="container">
                        <div class="row justify-content-center header-section" id="pdf-header-content">
                            <div class="col header-title">
                                @if(isSheet){
                                    <h1>Keuken-Sheet</h1>
                                    @if(establishment?.displayDayOfWeekOnSheet){
                                        <span class="dayOfEvent">{{this.dayOfWeek}}</span>
                                    }
                                }
                            </div>
                        </div>
                        @if(tender?.guestValues){
                            <div class="row justify-content-center header-section" id="pdf-header-content">
                                <div class="col sub-header-title">
                                    <span>{{getTenderHeader()}}</span>
                                </div>
                            </div>
                        }
                        @if(tender?.partyValues?.dateOfEvent){
                            <div class="row justify-content-center header-section" id="pdf-header-content">
                                <div class="col sub-header-title">
                                    <span>{{getTenderDateHeader()}}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            @if(!tender || !establishment){
                <div class="error-warning">
                    <div class="alert alert-danger" role="alert">
                        <strong>Whoops!</strong> Er is een fout opgetreden tijdens het laden van deze pagina... Probeer het later opnieuw.
                    </div>
                </div>
            }
            @if(!isSheet && !isKitchenSheet && !isInvoice && establishment?.tenderIntroduction?.length ?? 0 > 0){
                <div class="col tender-introduction">
                    @for(rule of establishment?.tenderIntroduction; track rule){
                        <div class="rule">
                            @if(rule == ''){
                                <span class="empty-rule">Empty Rule</span>
                            } @else {
                                <span>{{rule}}</span>
                            }
                        </div>
                    }
                </div>
            }
            @if(tender && establishment){
                <div class="tender-content">
                    @if(!isSheet && !isInvoice){
                        <div class="meta-data-row">
                            <div>
                                <span><strong>Datum</strong>: {{today | date:'dd-MM-yyyy'}}</span>
                                @if(tender.uniqueTenderNumber){
                                    <span><strong>Offertenummer</strong>: {{tender.uniqueTenderNumber}}</span>
                                }
                            </div>
                        </div>
                    }
                    <div class="tender-row">
                        <div class="col">
                            <div class="accordion" id="accordion">
                                @if(isInvoice){
                                    <div class="card accordion-item">
                                        <div class="card-header" id="headingInvoiceValues">
                                            <h5 class="mb-0 header-actions">
                                                <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInvoiceValues" aria-expanded="true" aria-controls="collapseInvoiceValues">
                                                    Factuur aan
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseInvoiceValues" class="collapse show" aria-labelledby="headingInvoiceValues" data-parent="#accordion">
                                            <div class="card-body invoice-values">
                                                <app-invoice-values [establishment]="establishment" [tender]="tender" [isReadOnly]="true"></app-invoice-values>                                
                                            </div>
                                        </div>
                                    </div>
                                }
                                @if(tender.guestValues && !isKitchenSheet && !isInvoice){
                                    <div class="card accordion-item">
                                        <div class="card-header" id="headingGuestValues">
                                            @if(isSheet){
                                                <h5 class="mb-0 header-actions">
                                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGuestValues" aria-expanded="true" aria-controls="collapseGuestValues">
                                                        Gastgegevens gegevens
                                                    </button>
                                                </h5>
                                            } @else {
                                                <h5 class="mb-0 header-actions">
                                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGuestValues" aria-expanded="true" aria-controls="collapseGuestValues">
                                                        Uw gegevens
                                                    </button>
                                                </h5>
                                            }
                                        </div>
                                        <div id="collapseGuestValues" class="collapse show" aria-labelledby="headingGuestValues" data-parent="#accordion">
                                            <div class="card-body">
                                                <app-guest-values [isSheet]="isSheet" [establishment]="establishment" [isReadOnly]="true" [tender]="tender"></app-guest-values>
                                            </div>
                                        </div>
                                    </div>
                                }
                                @if(establishment && !isKitchenSheet && !isSheet){
                                    <div class="card accordion-item">
                                        <div class="card-header" id="headingEstablishmentValues">
                                            <h5 class="mb-0 header-actions">
                                                <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEstablishmentValues" aria-expanded="true" aria-controls="collapseEstablishmentValues">
                                                    Onze gegevens
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseEstablishmentValues" class="collapse show" aria-labelledby="headingEstablishmentValues" data-parent="#accordion">
                                            <div class="card-body">
                                                <app-establishment-values [establishment]="establishment" [isInvoice]="isInvoice"></app-establishment-values>
                                            </div>
                                        </div>
                                    </div>
                                }
                                @if(tender.partyValues && !isKitchenSheet && !isInvoice){
                                    <div class="card accordion-item">
                                        <div class="card-header" id="headingPartyValues">
                                            <h5 class="mb-0 header-actions">
                                                <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePartyValues" aria-expanded="true" aria-controls="collapsePartyValues">
                                            Gelegenheidsgegevens
                                        </button>
                                            </h5>
                                        </div>
                                        <div id="collapsePartyValues" class="collapse show" aria-labelledby="headingPartyValues" data-parent="#accordion">
                                            <div class="card-body">
                                                <app-party-values [isReadOnly]="true" [tender]="tender" [isSheet]="isSheet" [isKitchenSheet]="isKitchenSheet" [establishment]="establishment"></app-party-values>
                                            </div>
                                        </div>
                                    </div>
                                }
                                @if(tender.particularities && !isInvoice){
                                    <div class="card accordion-item">
                                        <div class="card-header" id="headingParticularities">
                                            <h5 class="mb-0 header-actions">
                                                <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseParticularities" aria-expanded="true" aria-controls="collapseParticularities">
                                                    Bijzonderheden
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseParticularities" class="collapse show" aria-labelledby="headingParticularities" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <span class="particularities">{{tender.particularities}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                @if(isSheet && tender.kitchenParticularities && !isInvoice){
                                    <div class="card accordion-item">
                                        <div class="card-header" id="headingKitchenParticularities">
                                            <h5 class="mb-0 header-actions">
                                                <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseKitchenParticularities" aria-expanded="true" aria-controls="collapseKitchenParticularities">
                                                    Opmerking Keuken
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseKitchenParticularities" class="collapse show" aria-labelledby="headingKitchenParticularities" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <span class="particularities" id="kitchenParticularities">{{tender.kitchenParticularities}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                @if((tender.timeLine?.showInTender || isSheet) && containsTimeLineRows() && !isInvoice){
                                    <div class="card accordion-item">
                                        <div class="card-header" id="headingTimeLine">
                                            <h5 class="mb-0 header-actions">
                                                <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTimeLine" aria-expanded="true" aria-controls="collapseTimeLine">
                                                Tijdspad
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTimeLine" class="collapse show" aria-labelledby="headingTimeLine" data-parent="#accordion">
                                            <app-timeline-values [isReadOnly]="true" [tender]="tender"></app-timeline-values>
                                        </div>
                                    </div>
                                }
                                <div class="card accordion-item">
                                    <div class="card-header" id="headingSummary">
                                        <h5 class="mb-0 header-actions">
                                            <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSummary" aria-expanded="true" aria-controls="collapseSummary">
                                                Samenvatting
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSummary" class="collapse show" aria-labelledby="headingSummary" data-parent="#accordion">
                                        <div class="card-body">
                                            <div class="summary-row">
                                                <app-tender-summary [establishmentSettings]="establishmentSettings" [isSheet]="isSheet" [isKitchenSheet]="isKitchenSheet" [isExportComponent]="true" [tender]="tender" [usedInInvoice]="isInvoice"></app-tender-summary>
                                            </div>
                                        </div>
                                    </div>
                                    @if(establishment.establishmentPolicies && !isSheet && !isInvoice){
                                        <div class="row policy-row">
                                            <div class="col policy">
                                                @for(rule of establishment.establishmentPolicies; track $index){
                                                    <div class="rule">
                                                        @if(rule == ''){
                                                            <span class="empty-rule">Empty Rule</span>
                                                        } @else {
                                                            <span>* {{rule}}</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>