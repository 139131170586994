import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TimeLineItem } from 'src/app/shared/objects/components/time-line-item';
import { Tender } from 'src/app/shared/objects/tender';

@Component({
  standalone: true,
  imports: [
    FormsModule
  ],
  selector: 'app-timeline-values',
  templateUrl: './timeline-values.component.html',
  styleUrls: ['./timeline-values.component.scss']
})
export class TimelineValuesComponent implements OnInit {

  @Input() tender?: Tender;
  @Input() isReadOnly?: boolean;

  constructor() { }

  ngOnInit(): void {
    if(this.tender?.timeLine?.timeLineItems?.length == 0){
      this.addTimeLineItem();
    }
  }

  /**
   * This method will add an item to the timeline section of the tender
   */
   addTimeLineItem(){
    if(!this.tender || !this.tender?.timeLine){
      return;
    }

    // Safety check
    if(!this.tender.timeLine.timeLineItems){
      this.tender.timeLine.timeLineItems = [];
    }

    // Add new item
    this.tender.timeLine.timeLineItems.push({
      $id: "",
      tenderId: this.tender.$id,
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
    });
  }

  /**
   * This method will remove an item from the timeline section of the tender
   */
   removeTimeLineItem(timeLineItem: TimeLineItem){
    if(!this.tender || !this.tender?.timeLine || !timeLineItem){
      return;
    }

    // Add new item
    this.tender.timeLine.timeLineItems = this.tender.timeLine.timeLineItems.filter(t => t != timeLineItem);
  }

}
