@if(!tender || !tender.timeLine){
    <div class="time-line-error">
        <div class="alert alert-danger" role="alert">
            <strong>Whoops!</strong> Er is een fout opgetreden tijdens het laden van de tijdlijn... Probeer het later opnieuw.
        </div>
    </div>
}
@if(tender && tender.timeLine && tender.timeLine.timeLineItems){
    <div class="card-body time-planning">
        <div class="row">
            <div class="form-group col">
                <div class="row planning-header">
                    <div class="col">
                        Starttijd
                    </div>
                    <div class="col">
                        Eindtijd
                    </div>
                    <div class="col-3">
                        Ruimte
                    </div>
                    <div class="col-5">
                        Activiteit beschrijving
                    </div>
                    <div class="col-md-auto header-button">
                        <button style="visibility: hidden;" type="button" class="btn btn-danger">-</button>
                    </div>
                </div>
            </div>
        </div>
        @if(tender.timeLine.timeLineItems.length == 0 && !isReadOnly){
            <div class="row no-lines">
                <span>Geen tijdspad om weer te geven. Gebruikt de + knop om nieuwe regels toe te voegen.</span>
            </div>
        }
        @for(timeLineItem of tender.timeLine.timeLineItems; track $index){
            <div class="row time-planning-wrapper">
                <div class="form-group col">
                    <div id="time-planning">
                        <div class="row planningItem">
                            <div class="col">
                                @if(isReadOnly){
                                    <input class="form-control-plaintext" type="time" [(ngModel)]="timeLineItem.startTimeLine" />
                                } @else {
                                    <input class="form-control" type="time" [(ngModel)]="timeLineItem.startTimeLine" />
                                }
                            </div>
                            <div class="col">
                                @if(isReadOnly){
                                    <input class="form-control-plaintext" type="time" [(ngModel)]="timeLineItem.endTimeLine" />
                                } @else {
                                    <input class="form-control" type="time" [(ngModel)]="timeLineItem.endTimeLine" />
                                }
                            </div>
                            <div class="col-3">
                                @if(isReadOnly){
                                    <input class="form-control-plaintext" type="text" [(ngModel)]="timeLineItem.location" />
                                } @else {
                                    <input maxlength="500" class="form-control" type="text" [(ngModel)]="timeLineItem.location" />
                                }
                            </div>
                            <div class="col-5">
                                @if(isReadOnly){
                                    <input class="form-control-plaintext" type="text" [(ngModel)]="timeLineItem.description" />
                                } @else {
                                    <input maxlength="1000" class="form-control" type="text" [(ngModel)]="timeLineItem.description" />
                                }
                            </div>
                            <div class="col-md-auto header-button">
                                @if(isReadOnly){
                                    <button type="button" style="visibility: hidden;" class="btn btn-danger">-</button>
                                } @else {
                                    <button type="button" class="btn btn-danger" (click)="removeTimeLineItem(timeLineItem)">-</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        @if(!isReadOnly){
            <div class="row">
                <div class="col actions">
                    <div class="form-group display-timeline-section">
                        <label for="display-timeline-in-tender">Weergeven in offerte</label>
                        <input type="checkbox" id="display-timeline-in-tender" class="form-controll" [(ngModel)]="tender.timeLine.showInTender">
                    </div>
                    <button type="button" class="btn btn-secondary" id="add-timeline-row" data-bs-toggle="tooltip" data-bs-placement="top" title="Nieuwe regel toevoegen" (click)="addTimeLineItem()"> + </button>
                </div>
            </div>
        }
    </div>
}