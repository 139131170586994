<div class="row">
    <div class="col">
        <h4>Factuur aanmaken</h4>
        <p>
            In onderstaand component kunt u de offerte gereed maken om deze om te zetten naar een factuur. <br>
            Verander waar nodig de aantallen van de arrangementen en voeg eventueel extra arrangementen toe die niet geoffereerd waren.
            <br> Gebruik de <strong>'Synchroniseren'</strong> knop om de aantallen en prijzen uit de offerte op te halen.
        </p>
        <div class="alert alert-info" role="alert">
            <i class="bi bi-info-circle"></i>Alle wijzigingen die gemaakt worden in dit component zijn enkel zichtbaar op de factuur en <strong>niet</strong> in de offerte. 
          </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col">
        <app-arrangement-values [tender]="tender" [establishment]="establishment" [usedInInvoicePreparation]="true" ></app-arrangement-values>
    </div>
</div>
@if(errorSaving){
    <div class="row mt-3">
        <div class="col">
            <div class="alert alert-danger" role="alert">
                Het opslaan van de offerte is mislukt. Hierdoor kan er geen factuur worden gegenereerd. Probeer het later opnieuw.
              </div>
        </div>
    </div>
}
<div class="row" [ngClass]="{'mt-4': !errorSaving, 'mt-2': errorSaving}">
    <div class="col row d-flex justify-content-end pr-0">
        <div class="col-4 pr-0">
            @if(tender?.invoiceValues){
                <div class="form-group d-flex flex-row align-items-center">
                    <label class="label-input" for="invoiceNumber"><strong>Factuurnummer</strong>:</label>
                    <input maxlength="60" type="input" class="form-control" id="invoiceNumber" placeholder="Factuurnummer" [(ngModel)]="tender!.invoiceValues!.invoiceNumber">
                </div>
            }
        </div>
        <div class="col-auto d-flex align-items-end generate-actions">
            <div class="d-flex align-items-end">
                <button class="btn btn-secondary" (click)="createInvoice()">
                    @if(isProcessingSavingTender){
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    }
                    <i class="bi bi-filetype-pdf"></i>Genereer Factuur</button>
                <button class="btn btn-secondary" (click)="close()"><i class="bi bi-x-lg"></i>Sluiten</button>
            </div>
        </div>
    </div>
</div>