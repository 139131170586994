@if(establishment){
    <div class="row">
        @if(establishment.establishmentName){
            <div class="form-group col-4">
                <label for="establishmentName" id="firstname-label">Vestigingsnaam</label>
                <input type="input" readonly class="form-control-plaintext" id="establishmentName" [(ngModel)]="establishment.establishmentName">
            </div>
        }
        @if(establishment.kvkNumber){
            <div class="form-group col-2">
                <label for="kvkNumber" id="firstname-label">KVK nummer</label>
                <input type="input" readonly class="form-control-plaintext" id="kvkNumber" [(ngModel)]="establishment.kvkNumber">
            </div>
        }
        @if(establishment.establishmentEmailAdress){
            <div class="form-group col-4">
                <label for="establishmentEmailAdress" id="firstname-label">E-mailadres</label>
                <input type="input" readonly class="form-control-plaintext" id="establishmentEmailAdress" [(ngModel)]="establishment.establishmentEmailAdress">
            </div>
        }
        @if(establishment.establishmentPhoneNumber){
            <div class="form-group col">
                <label for="establishmentPhoneNumber" id="firstname-label">Telefoonnummer</label>
                <input type="input" readonly class="form-control-plaintext" id="establishmentPhoneNumber" [(ngModel)]="establishment.establishmentPhoneNumber">
            </div>
        }
    </div>
    <div class="row">
        @if(establishment.establishmentAdressStreet){
            <div class="form-group col-4">
                <label for="establishmentAdressStreet" id="firstname-label">Straatnaam</label>
                <input type="input" readonly class="form-control-plaintext" id="establishmentAdressStreet" [(ngModel)]="establishment.establishmentAdressStreet">
            </div>
        }
        @if(establishment.establishmentAdressNumber){
            <div class="form-group col-2">
                <label for="establishmentAdressNumber" id="firstname-label">Huisnummer</label>
                <input type="input" readonly class="form-control-plaintext" id="establishmentAdressNumber" [(ngModel)]="establishment.establishmentAdressNumber">
            </div>
        }
        @if(establishment.establishmentAdressCity){
            <div class="form-group col-4">
                <label for="establishmentAdressCity" id="firstname-label">Plaats</label>
                <input type="input" readonly class="form-control-plaintext" id="establishmentAdressCity" [(ngModel)]="establishment.establishmentAdressCity">
            </div>
        }
        @if(establishment.establishmentAdressZipcode){
            <div class="form-group col">
                <label for="establishmentAdressZipcode" id="firstname-label">Postcode</label>
                <input type="input" readonly class="form-control-plaintext" id="establishmentAdressZipcode" [(ngModel)]="establishment.establishmentAdressZipcode">
            </div>
        }
    </div>
    <div class="row">
        @if(establishment.bankAccountNumber){
            <div class="form-group col-4">
                <label for="bankAccountNumberInput" id="firstname-label">IBAN-nummer</label>
                <input type="input" readonly class="form-control-plaintext" id="bankAccountNumberInput" [(ngModel)]="establishment.bankAccountNumber">
            </div>
        }
        @if(establishment.vatId){
            <div class="form-group col-2">
                <label for="vatIdInput" id="firstname-label">BTW-nummer</label>
                <input type="input" readonly class="form-control-plaintext" id="vatIdInput" [(ngModel)]="establishment.vatId">
            </div>
        }
    </div>
}
