
@if(showIntroduction){
    <div class="card introduction-card" >
        <div class="card-body">
            <div class="row">
                <h4>Taken overzicht</h4>
                <span>In onderstaande lijst worden alle taken getoond die ofwel open staan, ofwel afgerond zijn. Er kan gezocht worden op; naam, vervaldatum en status. De lijst kan tevens worden gesorteerd door op de kolom te klikken in een oplopende of aflopende volgorde. </span>
            </div>
            <div class="row quick-actions">
                <h5>Filters</h5>
                <div class="quick-actions-buttons">
                    <button class="btn btn-primary quick-tender-action-button" (click)="toggleShowCompleted()">
                        <i class="bi bi-check-circle"></i>
                        @if(showCompleted){
                            <span>Toon <strong>openstaande</strong> taken</span>
                        } @else {
                            <span>Toon taken met <strong>elke</strong> status</span>
                        }
                    </button>
                    <button class="btn btn-primary quick-tender-action-button" (click)="toggleShowUserTasks()">
                        <i class="bi bi-person"></i>
                        @if(showUserTasks){
                            <span>Toon taken van <strong>alle</strong> gebruikers</span>
                        } @else {
                            <span>Toon <strong>mijn</strong> taken</span>
                        }
                    </button>
                </div>
            </div>
        </div>
    </div>
}
<div class="card">
    <div class="card-body">
        <div class="row">
            @if(showCompleted){
                <h5 class="col card-title" id="active-tasks-anchor">Alle taken</h5>
            } @else {
                <h5 class="col card-title" id="active-tasks-anchor">Actieve taken</h5>
            }
            <div class="col-3 search">
                <i class="bi bi-search" data-toggle="tooltip" data-placement="top" title="Zoeken op: Naam, aanmaakdatum, vervaldatum en status"></i>
                <input [(ngModel)]="searchInput" (ngModelChange)="searchTasks()" class="form-control" type="text" placeholder="Zoeken...">
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    @if(!hideTenderName){
                        <th style="width: 20%" class="sortable" scope="col" (click)="sortTasksBy('tenderName')">Offerte naam
                            @if(sortedBy == 'tenderName' && sortedAscending){
                                <i class="bi bi-caret-down-fill"></i>
                            } @else if(sortedBy == 'tenderName' && !sortedAscending){
                                <i class="bi bi-caret-up-fill"></i>
                            }
                        </th>
                    }
                    <th style="width: 39%" class="sortable" scope="col" (click)="sortTasksBy('name')">Taak naam
                        @if(sortedBy == 'name' && sortedAscending){
                            <i class="bi bi-caret-down-fill"></i>
                        } @else if (sortedBy == 'name' && !sortedAscending){
                            <i class="bi bi-caret-up-fill"></i>
                        }
                    </th>
                    <th style="width: 10%" class="sortable" scope="col" (click)="sortTasksBy('attachedUsers')">Taak van
                        @if(sortedBy == 'attachedUsers' && sortedAscending){
                            <i class="bi bi-caret-down-fill"></i>
                        } @else if(sortedBy == 'attachedUsers' && !sortedAscending){
                            <i class="bi bi-caret-up-fill"></i>
                        }
                    </th>
                    <th style="width: 10%" class="sortable" scope="col" (click)="sortTasksBy('expiration date')">Vervaldatum
                        @if(sortedBy == 'expiration date' && sortedAscending){
                            <i class="bi bi-caret-down-fill"></i>
                        } @else if(sortedBy == 'expiration date' && !sortedAscending){
                            <i class="bi bi-caret-up-fill"></i>
                        }
                    </th>
                    <th style="width: 10%" class="sortable" scope="col" (click)="sortTasksBy('status')">Status
                        @if(sortedBy == 'status' && sortedAscending){
                            <i class="bi bi-caret-down-fill"></i>
                        } @else if(sortedBy == 'status' && !sortedAscending){
                            <i class="bi bi-caret-up-fill"></i>
                        }
                    </th>
                    @if(hideActions === true && !isViewer){
                        <th style="width: 13%" scope="col">Acties</th>
                    }
                </tr>
            </thead>
            @if(paginatedActiveTasks){
                <tbody>
                    @for(task of paginatedActiveTasks[activePage]; track task.$id; let i = $index){
                        <tr class="centered-td-content" [ngClass]="{'warning': deadLineInRange(task.deadlineDate) == true}">
                            @if(!hideTenderName){
                                <td>
                                   <div  class="tender-name">
                                        <span>{{getTenderName(task.$id)}}</span>
                                        <span class="edit-button" routerLink="/edit-tender/{{task.tenderId}}"><i class="bi bi-pencil"></i></span>
                                   </div>
                                </td>
                            }
                            @if(task.taskName){
                                <td>
                                    <div class="taskName-section">
                                        <span>
                                            {{task.taskName}}
                                        </span>
                                        @if(task.taskDescription){
                                            <div (click)="toggleTaskDescription(i)">
                                                <i class="bi bi-caret-down-square" id="toggleTask{{i}}"></i>
                                            </div>
                                        }
                                    </div>
                                    <div id="accordionTask{{i}}">
                                        <div id="collapseTask{{i}}" class="collapse" [attr.aria-labelledby]="'headerTask' + i" [attr.data-parent]="'#accordionTask' + i">
                                            <div class="task-description-header">
                                                Beschrijving:
                                            </div>
                                            <div class="task-description">
                                                {{task.taskDescription}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            } @else {
                                <td>
                                    Geen naam vastgesteld
                                </td>
                            }
                            <td>
                                @if(!task.joinedAssignedUserIds || task.joinedAssignedUserIds.split(',').length == 0){
                                    <span>Team</span>
                                } @else if(task.joinedAssignedUserIds.split(',').length > 0){
                                    <span class="attachedUsers">
                                        @for(attachedUser of getAttachedUsers(task); track attachedUser.$id){
                                            <span>
                                                @if(attachedUser.userId == user?.$id){
                                                    <strong>{{attachedUser.userName}}</strong>
                                                } @else {
                                                    <span>{{attachedUser.userName}}</span>
                                                }
                                            </span>
                                        }
                                    </span>
                                }
                            </td>
                            @if(task.deadlineDate){
                                <td>{{task.deadlineDate | date:"dd-MM-yyyy"}}</td>
                            } @else {
                                <td>Niet Vastgesteld</td>
                            }
                            <td class="status-line">
                                <div class="status-badge-section">
                                    <span class="badge badge-primary" (click)="onStatusExpanderClick(task.$id)" [ngStyle]="{'background-color': taskService.getStatusColor(task.status)}">
                                        <i class="{{taskService.getStatusIcon(task.status)}}"></i>
                                        {{task.status}}
                                    </span>
                                    @if(!isViewer){
                                        <div (click)="onStatusExpanderClick(task.$id)">
                                            <i class="bi bi-caret-down-fill"></i>
                                        </div>
                                    }
                                    @if(internalCommunicationSevice.openStatusTaskId == task.$id && internalCommunicationSevice.openStatusTaskId && !isViewer){
                                        <div class="other-status">
                                            @for(statusItem of taskService.taskStatusItems; track statusItem.description){
                                                <div class="row">
                                                    <div class="col">
                                                        <span (click)="statusSelectionChanged(task, statusItem.value)" class="badge badge-primary" [ngStyle]="{'background-color': taskService.getStatusColor(statusItem.value)}">
                                                            <i class="{{taskService.getStatusIcon(statusItem.value)}}"></i>
                                                            {{statusItem.description}}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </td>
                            @if(hideActions === true){
                                <td>
                                   <div class="task-actions">
                                    @if(!isViewer){
                                        <button class="btn btn-primary action-button" id="edit-task{{i}}" (click)="editTask(task.$id)">
                                            <i class="bi bi-pencil"></i>
                                        </button>
                                        <button class="btn btn-danger remove-button" id="remove-task{{i}}" (click)="removeTask(task.$id, i, true)">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    }
                                    <button class="btn btn-danger remove-confirmation" id="confirm-remove-task{{i}}" (click)="removeTask(task.$id, i, false)">Bevestig</button>
                                    <button class="btn btn-primary action-button cancel-remove" id="cancel-remove-task{{i}}" (click)="cancelTaskRemoval(i)"><i class="bi bi-x-circle"></i></button>
                                   </div>
                                </td>
                            }
                        </tr>
                    }
                </tbody>
            }
        </table>
        <div class="table-footer">
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li class="page-item" (click)="activePage = activePage - 1">
                        <a class="page-link" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    @for(subSet of paginatedActiveTasks; track subSet; let i = $index){
                        @if(i >= activePage - 5 && i <= activePage + 5){
                            <li class="page-item" (click)="activePage = i">
                                @if(i == activePage){
                                    <a class="page-link active-page">{{i + 1}}</a>
                                } @else {
                                    <a class="page-link">{{i + 1}}</a>
                                }
                            </li>
                        }
                    }
                    <li class="page-item" (click)="activePage = activePage + 1">
                        <a class="page-link" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
                <div class="per-page">
                    <div class="form-group">
                        <select class="form-select" [(ngModel)]="perPage" (ngModelChange)="paginateActiveTasks()">
                            <option [ngValue]="5"><span>5</span></option>
                            <option [ngValue]="10"><span>10</span></option>
                            <option [ngValue]="20"><span>20</span></option>
                            <option [ngValue]="50"><span>50</span></option>
                        </select>
                    </div>
                    <span>Taken per pagina</span>
                </div>
            </nav>
            <div class="show-completed">
                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="showCompleted" [(ngModel)]="showCompleted" (ngModelChange)="onCompletedChanged()">
                        <label class="form-check-label" for="showCompleted">
                        Toon afgeronde taken
                      </label>
                    </div>
                </div>
            </div>
            @if(activeTasks.length == 0){
                <div class="no-tasks"><span>Geen actieve taken gevonden</span></div>
            }
            <div class="row message-pop-up">
                @if(successFeedback){
                    <div class="success-warning message">
                        <div class="alert alert-success" role="alert">
                            <strong>Succes!</strong> {{successFeedback}}
                        </div>
                    </div>
                }
                @if(warningFeedback){
                    <div class="success-warning message">
                        <div class="alert alert-warning" role="alert">
                            <strong>Let op!</strong> {{warningFeedback}}
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>