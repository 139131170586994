import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { Establishment } from 'src/app/shared/objects/establishment';
import { Tender } from 'src/app/shared/objects/tender';

@Component({
  standalone: true,
  imports: [
    FormsModule, DatePipe
  ],
  selector: 'app-invoice-values',
  templateUrl: './invoice-values.component.html',
  styleUrls: ['./invoice-values.component.scss']
})
export class InvoiceValuesComponent implements OnInit, OnDestroy {

  @Input() tender?: Tender;
  @Input() isReadOnly?: boolean;
  @Input() establishment?: Establishment;
  today: Date;
  expirationDate?: Date;
  subscribtions: (Subscription | undefined)[] = []; 

  constructor(private communicationService: InternalCommunicationService) {
    this.today = new Date();
  }

  ngOnInit(): void {
    // Set the defaults for linking based on the Establischment settings. Only if tender is not yet created
    if(!this.tender?.$id && this.tender?.invoiceValues){
      this.tender.invoiceValues.ascriptionFromTender = this.establishment?.linkInvoiceAscriptionAndEmailAdress ?? false;
      this.tender.invoiceValues.emailAdressFromTender = this.establishment?.linkInvoiceAscriptionAndEmailAdress ?? false;
    }

    this.subscribtions.push(this.communicationService.proposedTenderName?.subscribe(() => {
      this.copyAscription(false);
      this.copyEmailAdress(false);
    }));

    // Set expiration date --> Default 14
    this.expirationDate = moment().add(this.establishment?.defaultDaysBeforeExpirering ?? 14, 'days').toDate();
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  /**
   * This method will copy the EmailAdress from the GuestValues section into the Invoice section
   */
  copyEmailAdress(toggle: boolean = true){
    if(!this.tender?.guestValues || !this.tender?.invoiceValues){
      return;
    }

    // Toggle AdressFromTender if needed
    if(toggle){
      this.tender.invoiceValues.emailAdressFromTender = !this.tender.invoiceValues.emailAdressFromTender;
    }

    if(this.tender.invoiceValues.emailAdressFromTender){
      this.tender.invoiceValues.emailAdress = this.tender.guestValues.emailAdress;
    }
  }

  
  /**
   * This method will copy the correct ascription from the GuestValues section into the Invoice section
   */
  copyAscription(toggle: boolean = true){
    if(!this.tender?.guestValues || !this.tender?.invoiceValues){
      return;
    }

    // Toggle AscriptionFromTender if needed
    if(toggle){
      this.tender.invoiceValues.ascriptionFromTender = !this.tender.invoiceValues.ascriptionFromTender;
    }

    if(this.tender.invoiceValues.ascriptionFromTender){
      // Use Company name if present, else use the first- and lastname
      if(this.tender.guestValues.companyName){
        this.tender.invoiceValues.ascription = this.tender.guestValues.companyName;
      } else if(this.tender.guestValues.insertion) {
        this.tender.invoiceValues.ascription = `${this.tender.guestValues.firstname ?? ""} ${this.tender.guestValues.insertion} ${this.tender.guestValues.lastname ?? ""}`;
      } else {
        this.tender.invoiceValues.ascription = `${this.tender.guestValues.firstname ?? ""} ${this.tender.guestValues.lastname ?? ""}`;
      }
    }

  }

  /**
   * This method will return the person/company where the invoice is adressed to
   * @returns 
   */
  getAdressedTo(){
    if(!this.tender?.guestValues){
      return '';
    }
    
    // Return companyName if present
    if((this.tender.guestValues.companyName?.length ?? 0) > 0){
      return this.tender.guestValues.companyName
    }

    // Return personal attention else
    if(this.tender.guestValues.insertion){
      return `${this.tender.guestValues.firstname} ${this.tender.guestValues.insertion} ${this.tender.guestValues.lastname}`;
    } else if(this.tender.guestValues.lastname){
      return `${this.tender.guestValues.firstname} ${this.tender.guestValues.lastname}`;
    } 
    
    return '';
  }

  /**
   * This method will return a physical adres if present and else a e-mail adres
   */
  getAdress(){
    if(!this.tender){
      return '';
    }

    // Return physical adres
    if(this.tender.guestValues?.street && this.tender.guestValues.city && this.tender.guestValues.number){
      return `${this.tender.guestValues.street} ${this.tender.guestValues.number} - ${this.tender.guestValues.zipcode} - ${this.tender.guestValues.city}`
    } else{
      return this.tender.invoiceValues?.emailAdressFromTender ? this.tender.guestValues?.emailAdress : this.tender.invoiceValues?.emailAdress;
    }
  }
}
