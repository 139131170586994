import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Establishment } from 'src/app/shared/objects/establishment';

@Component({
  standalone: true,
  imports: [
    FormsModule
  ],
  selector: 'app-establishment-values',
  templateUrl: './establishment-values.component.html',
  styleUrls: ['./establishment-values.component.scss']
})
export class EstablishmentValuesComponent implements OnInit {

  @Input() establishment?: Establishment;
  @Input() isInvoice?: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
