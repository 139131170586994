import { Injectable } from '@angular/core';
import { InternalCommunicationService } from '../Communication/internal-communication.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private communicationService: InternalCommunicationService) { }

  /***
   * This method will check which color the text needs to be
   */
  getTextColor(brandColor: string | undefined): string{
    if(!brandColor){
      return "#1a1a1a";
    }

    var c = brandColor.substring(1);
    var rgb = parseInt(c, 16);
    var r = (rgb >> 16) & 0xff; 
    var g = (rgb >>  8) & 0xff;
    var b = (rgb >>  0) & 0xff;

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    if (luma < 85) {
        return "#f2f2f2"; 
    }
    return "#1a1a1a";
  }

  /**
   * When click somewhere on the screen deativate open pop-ups
   */
  onClickLostFocus() {
    if (this.communicationService.openStatusTenderIdChanged) {
      this.communicationService.openStatusTenderIdChanged = false;
      return;
    }

    if (this.communicationService.openStatusTaskIdChanged) {
      this.communicationService.openStatusTaskIdChanged = false;
      return;
    }

    this.communicationService.openStatusTenderIdChanged = false;
    this.communicationService.openStatusTenderId = undefined;

    this.communicationService.openStatusTaskIdChanged = false;
    this.communicationService.openStatusTaskId = undefined;
  }

  /**
   * This method will expand or collapse all fields
   */
  expandOrCollapseAll(expandAccordion: boolean){
    var tenderWrapper = document.getElementById('accordion');

    if (tenderWrapper != null) {
      var accordions = tenderWrapper.getElementsByClassName('collapse');

      if (accordions != null) {
        for (var i = 0; i < accordions.length; i++) {

          
          if(expandAccordion){
            accordions[i].classList.remove("hide");
            accordions[i].classList.add("show");
          } else{
            accordions[i].classList.remove("show");
            accordions[i].classList.add("hide");
          }
        }
      }
    }
  }
}
